.txt_title {
    width: 200px;
    text-align: center;
}

.txt_title_pro {
    width: 400px;
}

.txt_btn {
    width: 100px;
    text-align: center;
}

.card_interv {
    display: flex;
    flex-direction: column;
}

.card_proga {
    display: flex;
    border: none;
    cursor: pointer;
    align-items: center;
    background-color: #d6ecff;
    height: 75px;
    border-radius: 30px;
    margin-bottom: 10px;
}

.card_ento {
    display: flex;
    border: none;
    cursor: pointer;
    align-items: center;
    background-color: #f6e7df;
    height: 75px;
    border-radius: 30px;
    margin-bottom: 10px;
}

.card_pedo {
    display: flex;
    border: none;
    cursor: pointer;
    align-items: center;
    background-color: #ecebf4;
    height: 75px;
    border-radius: 30px;
    margin-bottom: 10px;
}

.card_ence {
    display: flex;
    border: none;
    cursor: pointer;
    align-items: center;
    background-color: #dbece2;
    height: 75px;
    border-radius: 30px;
}

.card_collum_da {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.card_rows_int {
    display: flex;
}

.card_ponto_list {
    padding: 2px 10px;
    background-color: #edede9;
    margin: 4px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
}

.card_title_select {
    display: flex;
    width: 100%;
    background-color: #023047;
    margin: 4px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}