.body_edit {
    display: flex;
}

.collum_1 {
    flex: 0 0 270px;
    margin-right: 10px;
}

.collum_2 {
    flex: 1;
}

.card_header {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.btn_header {
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    background-color: #fff;
    height: 50px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 8px;
    transition: 0.5s;
    text-align: left;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.btn_header_clicked {
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    background-color: #001d3d;
    color: #fff;
    height: 50px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 5px;
    border-radius: 22px;
    transition: 0.5s;
    font-size: 15px;
    cursor: pointer;
    margin-top: 8px;
    text-align: left;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.brn_back_edit {
    background-color: #252525;
    color: #fff;
    height: 35px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 5px;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 8px;
    transition: 0.5s;
    text-align: left;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.icon_btn {
    font-size: 25px;
    margin-right: 10px;
}

.card_title {
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    background-color: #001d3d;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
}

.card_header_select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.btn_edit {
    border: none;
    background-color: #e63946;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn_add_page {
    border: none;
    background-color: #e63946;
    padding: 5px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn_edit_ped {
    border: none;
    background-color: #e63946;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn_edit_ped:last-child {
    margin-left: 20px;
}

.btn_edit_niv {
    border: none;
    background-color: #168aad;
    margin-left: 5px;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
}

.btn_remo_niv {
    border: none;
    background-color: #e63946;
    margin-left: 5px;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
}

.btn_comp_niv {
    border: none;
    background-color: #2a9d8f;
    margin-left: 10px;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
}

.card_ent_ed {
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    background-color: #fff;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* duas colunas com a mesma largura */
    grid-template-rows: repeat(2, 1fr);
    /* duas linhas com a mesma altura */
    gap: 10px;
    /* espaço entre as divs */
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 15px;
}

.card_ped {
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    background-color: #fff;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.card_data_ent {
    display: flex;
    flex-direction: column;
}

.card_ped_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.list_pacotes {
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list_pacotes:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.card_qnt_pc {
    display: flex;
    align-items: start;
    margin-bottom: 5px;
}

.card_enc {
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    background-color: #fff;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* duas colunas com a mesma largura */
    gap: 10px;
    /* espaço entre as divs */
}

.card_null_fotos {
    background-color: #fca311;
    padding: 5px;
    border-radius: 6px;
    width: 100%;
    margin-right: 10px;
}

.card_line {
    display: flex;
    justify-content: space-between;
}

.file-upload label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 100%;
    margin: 10px;
    background-color: #313131;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
}

.file-upload label:hover {
    background-color: #001d3d;
}