.body-login {
    background-color: #14213d;
    overflow: hidden;
}

.area-login {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.btn_hc {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.btn_hc:hover {
    background-color: rgba(255, 255, 255, 0.3)
}

.card-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
    width: 30%;
    height: 70%;
    border-radius: 20px;
    padding: 40px;
    margin-right: 30px;
}

.card-logo img {
    width: 260px;
    height: 350px;
}

.login {
    background-color: #edede9;
    width: 355px;
    display: flex;
    flex-direction: column;
    padding: 35px;
    align-items: center;
    border-radius: 20px;
}

.login input {
    margin-top: 10px;
    width: 100%;
    background-color: #fff;
    color: black;
    border: 2px solid #8d99ae;
    height: 45px;
    outline: none;
    border-radius: 8px;
    padding-left: 10px;
}

input::placeholder {
    color: #fff;
}

.msgErro {
    margin-top: 10px;
    background-color: #ef233c;
    width: 100%;
    padding: 4px;
    text-align: center;
    border-radius: 5px;
    transition: 0.5s;
}

.msgErro a {
    color: #fff;
}