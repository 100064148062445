.btn_add_int {
    border-width: 0;
    background-color: #14213d;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.btn_add_pessoa {
    border-width: 0;
    background-color: #34a0a4;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.btn_quit_int {
    border-width: 0;
    background-color: #ba0c0c;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    height: 40px;
}

.btn_cd_inter {
    border-style: solid;
    border-color: #14213d;
    background-color: none;
    padding: 5px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 5px;
}


.btn_add_int:hover {
    background-color: #003049;
}

.select {
    flex: 1;
}

.text_oficio {
    hyphens: none;
    color: blue;
}

.btn_add_org {
    border-style: solid;
    background-color: none;
    color: #14213d;
    padding: 8px;
    border-color: #14213d;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    flex: 1;
    cursor: pointer;
}

.btn_add_org:hover {
    background-color: #8d99ae;
    color: white;
}