.body_home {
    padding: 20px;
}

.card_user_job {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.circle_user {
    display: flex;
    background-color: #669bbc;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.btn_quit_resu {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #495057;
    border: none;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
    color: #fff;
}

.btn_backup {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffba08;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    color: #fff;
    height: 35px;
    margin-right: 6px;
}

.btn_backup_two {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #003566;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    height: 35px;
    margin-top: 10px;
}

.btn_play_user {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6c757d;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    height: 25px;
    margin-top: 10px;
}

.btn_edicao {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4CAF50;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    color: #fff;
    height: 35px;
    margin-right: 6px
}

.btn_resumo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3498DB;
    border: none;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    color: #fff;
    height: 35px;
}

.card_projeto_li {
    border-left: 4px solid #003566;
    padding-left: 6px;
    width: 280px;
    text-align: start;
}

.card_projeto_li_off {
    border-left: 4px solid #b7b7a4;
    padding-left: 6px;
}

.btn_refresh_data {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6c757d;
    padding: 2px;
    border-radius: 5px;
    border: none;
    font-size: 13px;
    cursor: pointer;
    color: #fff;
    margin-left: 6px;
}