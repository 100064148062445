
.barra_lateral{
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 15%;
    background-color: #0a2743;
}
.text_barra{
    font-family: 'Arimo', sans-serif;
    font-size: 16px;
    color: #FFF;
}
.card_text_barra{
    padding: 10px;
    list-style: none;
    display: flex;
    align-items: center;
}
.card_img_barra{
    text-align: center;
    padding: 20px;
    list-style: none;
    display: flex;
    align-items: center;
}
.img_aipa{
    width: 180px;
}
.card_sair_barra{
    position: fixed;
    bottom: 20px;
    left: 20px;
    text-align: center;
    background-color: #FFF;
    border-radius: 25px;
    padding: 5px;
    width: 50px;
}
.text_sair_barra{
    font-family: 'Arimo', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #0a2743;
}