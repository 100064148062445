.container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.container_card {
    margin-right: 10px;
}

.card_processos {
    margin-top: 10px;
    padding: 10px;
    background-color: #13678a;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-width: 130px;
    text-align: center;
}

.card_processos-b {
    padding: 10px;
    background-color: #e6e6e6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 150px;
    text-align: center;
}

.text_processos {
    font-weight: 500;
    color: #fff;
}

.card_list_pontos {
    height: 70px;
    max-width: 170px;
    overflow-y: scroll;
    background-color: #e6e6e6;
    margin-top: 10px;
}

.btnplus {
    width: 100%;
    border: none;
    border-radius: 10px;
    height: 25px;
    background-color: #505050;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 5px;
}

.card_bar {
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    justify-content: center;
    width: 700px;
    margin-bottom: 15px;
}

.card_pie {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 700px;
    margin-bottom: 15px;
}

.grid_item {
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
}

.text_tl {
    font-size: 18px;
    margin-bottom: 15px;
}

.header_txt {
    font-size: 22px;
    font-weight: 600;
}

.select_proj_d {
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn_back_db {
    width: 80px;
    border: none;
    border-radius: 10px;
    height: 24px;
    background-color: #505050;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.card_herder_back{
    display: flex;
    align-items: center;
}