body {
    background-color: unset;
}

.bodyRegister {
    margin-top: 2px;
}

input {
    color: black;
}

input::placeholder {
    color: black;
}

.cd {
    width: '100%';
}

.form_cd {
    display: flex;
    flex-direction: column;
}

Select {
    margin-top: 10px;
}

.btn_cd_n {
    background-color: #001d3d;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 250px;
    align-self: center;
}

.btn_cd_n:hover {
    background-color: #1d4c7e;
}

.input {
    padding: 8px;
    margin-bottom: 10px;
}

.select {
    margin-bottom: 10px;
}

.text_one {
    font-size: 20px;
    font-weight: 600;
}

.card_text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.card_btns_pc {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.card_evids_cd {
    background-color: #edede9;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    text-align: center;
    margin-right: 20px;
}

.cd_evid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.cd_evid_in {
    width: '100%';
    flex-basis: 250px 400px;
}

.card_ev_plus {
    text-align: center;
    margin-right: 20px;
}

.btn_plus {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.container_btns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.btn_evid {
    background-color: #001d3d;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 180px;
    align-self: center;
}

.text_qnt {
    padding: 10px;
    text-align: center;
    width: 30px;
    background-color: #1d4c7e;
    border-radius: 10px;
    color: white;
}

.btn_null {
    background-color: white;
    border: none;
    padding-right: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    transition-duration: 0.4s;
    cursor: pointer;
    align-self: center;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
}

.file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-upload input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.file-upload label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 230px;
    background-color: #e76f51;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
}

.file-upload label:hover {
    background-color: #f4a261;
}

.file-name {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #999;
}

.file_upload_btn {
    display: flex;
}

.slider {
    width: 400px;
    height: auto;
    margin: 0 auto;
    align-items: center;
}

.slider img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.card_data{
    display: flex;
    flex-direction: column;
    width: 305px;
}

.card_ponto{
    background-color: #0a9396;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 5px;
}

.card_ponto_text {
    color: #fff;
}

.card_ent {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* duas colunas com a mesma largura */
    grid-template-rows: repeat(2, 1fr); /* duas linhas com a mesma altura */
    gap: 10px; /* espaço entre as divs */
    background-color: #edede9;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 10px;
}

.card_ent_text {
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
}


.card_ent_text_title {
    margin-bottom: 5px;
    border-radius: 10px;
}

.slick-dots {
    position: absolute;
    top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.slick-dots li {
    margin: 0 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FFF;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.slick-dots li.slick-active {
    background-color: #fff;
}

.btn_null_save {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    background-color: #001d3d;
    border: none;
    padding: 5px;
    border-radius: 10px;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
    height: 40px;
}

.text_save{
    font-size: 18px;
    margin-left: 5px;
}

.header_btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.body_upload{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

.text_update{
    font-weight: 600;
    margin-top: 10px;
}