.car_ficha {
    margin: 20px
}

@media screen {
    .footer {
        display: none;
    }

    .container_capa {
        display: none;
    }

    .img_aipa_capa {
        display: none;
    }

    .img_hc_capa {
        display: none;
    }

    .card_img_hc_capa {
        display: none;
    }

    .button_send {
        background-color: #1d1d1d;
        border-radius: 10px;
        border: none;
        color: white;
        padding: 12px 16px;
        cursor: pointer;
        display: flex;
        transition-duration: 0.4s;
        margin-bottom: 15px;
    }

    .button_send:hover {
        background-color: #5f5f5f;
    }
}

@media print {
    @page {
        size: auto;
        margin: 5mm;
    }

    .page-divide {
        page-break-after: always;

    }

    .footer {
        bottom: -120px;
        left: 0;
        position: fixed;
        background-color: #E8e8e8;
        padding: 5px;
        border-radius: 10px;
    }

    .text_footer {
        font-family: 'Arimo', sans-serif;
        font-size: 11px;
    }

    .container_capa {
        top: 0;
        left: 0;
        position: relative;
        background-color: #0a2743;
        border-radius: 20px;
    }

    .img_aipa_capa {
        width: 250px;
    }

    .img_hc_capa {
        width: 450px;
    }

    .card_img_hc_capa {
        bottom: -300px;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    .card_img_capa {
        width: 800px;
        height: 800px;
        margin: 20px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .text_title_capa {
        font-family: 'Arimo', sans-serif;
        font-size: 30px;
        color: #FFF;
        font-weight: bold;
    }

    .card_text_capa {
        margin: 30px;
    }

    .card_text_border_capa {
        border-top: 1px solid #FFF;
    }

    .text_capa {
        font-family: 'Arimo', sans-serif;
        font-size: 15px;
        color: #FFF;
        font-weight: bold;
    }

    .button_send {
        display: none;
    }

}

.container {
    width: 100%;
}

.page_print {
    padding: 15px;
}

.page_print_border {
    width: 70%;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.img_aipa {
    width: 120px;
}

.card_img {
    display: grid;
    grid-template-columns: auto 120px;
    padding: 3px;
}

.card_container {
    background-color: #0a2743;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    align-self: center;
    margin-bottom: 3px;
    margin-right: 10px;
    width: '100%';
}

.card_container_meio {
    background-color: #7f7f7f;
    padding: 1px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 3px;
}

.text_title_ficha {
    font-family: 'Arimo', sans-serif;
    font-size: 12px;
    color: #FFF;
}

.container_projeto {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
}

.card_projeto {
    display: grid;
    grid-template-columns: auto auto;
}

.card_info {
    background-color: #08b161;
    padding: 3px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card_info_pt {
    background-color: #08b161;
    padding: 3px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card_info_result {
    background-color: #E8e8e8;
    padding: 3px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card_info_result_pt {
    background-color: #C5f1dc;
    padding: 3px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card_info_pd {
    background-color: #Cecece;
    padding: 3px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card_info_result_pd {
    background-color: #F1f1f1;
    padding: 3px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text_font {
    font-family: 'Arimo', sans-serif;
    font-size: 11px;
}

.text_font_bold {
    font-family: 'Arimo', sans-serif;
    font-size: 11px;
    font-weight: bold;
}

.card_context_amb {
    display: grid;
    grid-template-columns: auto auto;
}

.container_pedologia {
    display: grid;
    grid-template-columns: 20% 20% 20% 25% 15%;
}

.card_ped_tl {
    background-color: #Cecece;
    padding: 3px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 3px;
    margin-bottom: 3px;
}

.card_ped_info {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 3px;
    margin-right: 3px;
    border-bottom: 1px solid #d7d7d7;
}

.card_mat_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 3px;
    margin-right: 3px;
    border-bottom: 1px solid #d7d7d7;
}

.image {
    width: 100%;
    border-radius: 10px;
}

.card_image {
    width: 80%;
    margin: 0 auto;
    display: block;
    display: grid;
    grid-template-columns: auto auto;
}

.card_image_details {
    margin: 5px
}

.card_fechamento {
    text-align: center;
}

.card_tittle_print {
    text-align: center;
    margin: 15px
}

.text_tittle_print {
    font-family: 'Arimo', sans-serif;
    font-size: 20px;
}

.app {
    display: flex;
    flex-direction: row;
}

.button_lat {
    background-color: #1d1d1d;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 12px 16px;
    cursor: pointer;
    display: flex;
    transition-duration: 0.4s;
    margin-bottom: 15px;
}

.button_lat:hover {
    background-color: #5f5f5f;
}

.container_button_print {
    display: flex;
    justify-content: space-between;
}

.text_btn {
    margin-left: 5px;
    font-size: 15px;
}

.select_projeto {
    font-family: 'Arimo', sans-serif;
}

.card_select {
    margin-bottom: 15px;
    width: 50%;
}

.btns_parts {
    display: inline-block;
    margin-right: 10px;
}

.btn_on_parts {
    background-color: #a8a8a8;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 5px 16px;
    cursor: pointer;
    display: flex;
    transition-duration: 0.4s;
    margin-bottom: 5px;
}

.btn_on_parts:hover {
    background-color: #5f5f5f;
}

.card_parts_photo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.btn_pt_pv {
    border-width: 0;
    background-color: #01497c;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
    color: #fff;
    font-weight: 400;
}

.btn_pt_pv:hover {
    background-color: #013a63;
}

@media print {
    .card_parts_photo {
        display: none;
    }
}