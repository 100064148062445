* {
  font-family: 'Inter', sans-serif;
}

.card_total {
  margin: 15px
}

a {
  margin-bottom: 2px;
}

:root {
  --input-border: #8b8a8b;
  --input-focus-h: 210;
  --input-focus-s: 100%;
  --input-focus-l: 42%;
}

.input {
  font-size: 16px;
  font-size: Max(16px, 1em);
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: #fff;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  transition: 180ms box-shadow ease-in-out;
}

.input:focus {
  border-color: hsl(var(--input-focus-h),
      var(--input-focus-s),
      var(--input-focus-l));
  box-shadow: 0 0 0 3px hsla(var(--input-focus-h),
      var(--input-focus-s),
      calc(var(--input-focus-l) + 40%),
      0.8);
  outline: 3px solid transparent;
}

.container_cd {
  width: 100%
}

.btn_cd {
  border: none;
  background-color: #0a397c;
  color: #fff;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  width: 120px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.btn_cd:hover {
  background-color: #245fb0;
}

.select_projeto {
  margin-top: 10px;
}

.table_exp {
  width: 800px;
}

.table_title {
  padding: 2px;
  text-align: center;
  border: 2px solid #ddd;
  background-color: #ddd;
  font-size: 14px;
  border-radius: 10px;
}

.table_txt {
  padding: 2px;
  text-align: center;
  border: 2px solid #ddd;
  font-size: 14px;
  border-radius: 10px;
}

.table th {
  background-color: #f2f2f2;
  color: #333;
}

.table tr:hover {
  background-color: #f5f5f5;
}

.upload_box {
  background: white;
  outline: none;
  width: 407px;
  border: 2px solid #858585;
  border-radius: 5px;
  margin-bottom: 10px;
}

::-webkit-file-upload-button {
  color: white;
  background: #b8b8b8;
  width: 150px;
  padding: 10px;
  border: none;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
}

::-webkit-file-upload-button:hover {
  background: #b8b8b8;
}