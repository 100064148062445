.body_aipa {
    background-color: #edede9;
    height: 100vh;
}

.card_center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.into_btn_header{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.into_btn_header a{
    margin-left: 5px;
}

.btn_cd_home {
    background-color: #001d3d;
    border: none;
    color: #fff;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 150px;
    margin-right: 10px;
}

.btn_cd_home:hover {
    background-color: #617b85;
}

.btn_cd_home_clicked {
    background-color: #304b55;
    border: 1px;
    border-color: #2b2d42;
    color: #fff;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 150px;
    margin-right: 10px;
}

.btn_cd_home_clicked:hover {
    background-color: #617b85;
}

.card_home_new {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    margin: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #fff;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    height: 65vh;
    overflow-y: scroll;
    width: 800px;
}

.card_home_edit{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    margin: 20px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #fff;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    height: 65vh;
    overflow-y: scroll;
    width: 1000px;
}

.card_register {
    width: 700px;
}

.card_edit{
    width: 900px;
}

.header {
    height: 100px;
    background-color: #001d3d;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img_aipa_header {
    width: 175px;
    padding: 20px;
}

.btn_quit {
    background-color: #fff;
    border: none;
    width: 100px;
    padding: 5px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 10px;
    margin-right: 20px;
}