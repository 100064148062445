.myTable {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.myTable th,
.myTable td {
    border: 1px solid #495057;
    padding: 4px;
    font-size: 15px;
}

.myTable th {
    background-color: #94d2bd;
    font-size: 15px;
}