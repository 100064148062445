* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.container {
    display: flex;
}

main {
    width: 100%;
    padding: 20px;
}

.Sidebar {
    height: 100vh;
    width: 300px;
    background-color: #0a2743;
}

.sidebar_list {
    height: auto;
    padding: 0;
    width: 100%;
}

.sidebar_list .row {
    width: 100%;
    height: 60px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: 'Arimo', sans-serif;
}

.sidebar_list .row:hover {
    cursor: pointer;
    background-color: #05192c;
}

.sidebar_list #active {
    background-color: #05192c;
}

.row #icon {
    flex: 30%;
    display: grid;
    place-items: center;
}

.row #title {
    flex: 70%;
}

.btn_quit_ad{
    width: 150px;
    border:none;
    background-color: #fff;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    position: absolute;
    left: 40px;
    bottom: 25px;
}

.body_children{
    margin: 0;
    padding: 0;
    background-color: #edede9;
}