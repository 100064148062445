.btns_edits {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.btn_quit_proj {
    background-color: #2b2d42;
    height: 35px;
    padding: 5px;
    border-radius: 6px;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    border: none;
    color: #fff;
}