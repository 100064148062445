.btn_home_veri {
    border: 1px solid rgba(4, 111, 172, 0.8);
    background-color: rgba(4, 111, 172, 0.6);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.btn_home_veri:hover {
    background-color: rgba(4, 111, 172, 0.8)
}