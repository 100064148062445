.body {
    padding: 20px;
}

.grids {
    display: inline-grid;
    grid-template-columns: 450px 550px;
    gap: 20px;
}

.alerta_successful {
    padding: 15px;
    background-color: #DCF1E4;
    border-radius: 5px;
    border: solid 1px #00d34c;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition-duration: 0.4s;
    margin-bottom: 15px;
}

.card_alerta_successful {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_alerta_successful {
    font-weight: bolder;
    margin-left: 10px;
    color: #037b2f;
}

.edit_page {
    background-color: #EAEAEA;
    margin-left: 20px;
    width: 20vw;
    border-radius: 20px;
}

.text_edit_title {
    font-weight: bolder;
}

.card_input_user {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.add_user {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 15px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
}

.card_map_user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #a0a0a0;
    align-items: center;
    position: relative;
}

.btn_edit_one {
    border: none;
    background-color: #0a9396;
    border-radius: 4px;
    padding: 2px 6px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 6px;
    color: #fff;
    cursor: pointer;
}

.btn_edit_two {
    border: none;
    background-color: #ee9b00;
    border-radius: 4px;
    padding: 2px 6px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 6px;
    color: #fff;
    cursor: pointer;
}

.btn_edit_final {
    border: none;
    background-color: #ae2012;
    border-radius: 4px;
    padding: 2px 6px;
    margin-top: 4px;
    margin-bottom: 4px;
    color: #fff;
    cursor: pointer;
}

.user_infos {
    display: flex;
    flex-direction: row;
    padding: 4px;
}

.selo_user {
    font-size: 12px;
    border-radius: 5px;
    padding: 2px 4px;
    color: #fff;
    margin-left: 6px;
    background-color: #e63946;
}

.selo_proj {
    font-size: 12px;
    border-radius: 5px;
    padding: 2px 4px;
    color: #fff;
    margin-left: 6px;
    background-color: #495057;
}

.botoes-adicionais {
    position: absolute;
    z-index: 2;
    background-color: #fff;
    padding: 0 8px;
    right: 0;
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.btn_hide{
    border: none;
    background-color: #fff;
    font-size: 18px;
    cursor: pointer;
}